/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationRequestSchemaAllOf
 */
export interface ApplicationRequestSchemaAllOf {
    /**
     * name of the application
     * @type {string}
     * @memberof ApplicationRequestSchemaAllOf
     */
    name: string;
    /**
     * description of the application
     * @type {string}
     * @memberof ApplicationRequestSchemaAllOf
     */
    description?: string | null;
    /**
     * environment for the application
     * @type {string}
     * @memberof ApplicationRequestSchemaAllOf
     */
    env_type: ApplicationRequestSchemaAllOfEnvTypeEnum;
}


/**
 * @export
 */
export const ApplicationRequestSchemaAllOfEnvTypeEnum = {
    PROD: 'PROD',
    TEST: 'TEST'
} as const;
export type ApplicationRequestSchemaAllOfEnvTypeEnum = typeof ApplicationRequestSchemaAllOfEnvTypeEnum[keyof typeof ApplicationRequestSchemaAllOfEnvTypeEnum];


/**
 * Check if a given object implements the ApplicationRequestSchemaAllOf interface.
 */
export function instanceOfApplicationRequestSchemaAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "env_type" in value;

    return isInstance;
}

export function ApplicationRequestSchemaAllOfFromJSON(json: any): ApplicationRequestSchemaAllOf {
    return ApplicationRequestSchemaAllOfFromJSONTyped(json, false);
}

export function ApplicationRequestSchemaAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationRequestSchemaAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'env_type': json['env_type'],
    };
}

export function ApplicationRequestSchemaAllOfToJSON(value?: ApplicationRequestSchemaAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'env_type': value.env_type,
    };
}

