/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationResponseSchema
 */
export interface ApplicationResponseSchema {
    /**
     * ID
     * @type {number}
     * @memberof ApplicationResponseSchema
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseSchema
     */
    readonly deleted_at?: Date | null;
    /**
     * the quentry careteam id
     * @type {string}
     * @memberof ApplicationResponseSchema
     */
    quentry_careteam_id?: string | null;
    /**
     * name of the application
     * @type {string}
     * @memberof ApplicationResponseSchema
     */
    name: string;
    /**
     * description of the application
     * @type {string}
     * @memberof ApplicationResponseSchema
     */
    description?: string | null;
    /**
     * environment for the application
     * @type {string}
     * @memberof ApplicationResponseSchema
     */
    env_type: ApplicationResponseSchemaEnvTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponseSchema
     */
    readonly organization_id?: number;
    /**
     * id used for authentication
     * @type {string}
     * @memberof ApplicationResponseSchema
     */
    readonly client_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseSchema
     */
    readonly last_used_at?: Date | null;
}


/**
 * @export
 */
export const ApplicationResponseSchemaEnvTypeEnum = {
    PROD: 'PROD',
    TEST: 'TEST'
} as const;
export type ApplicationResponseSchemaEnvTypeEnum = typeof ApplicationResponseSchemaEnvTypeEnum[keyof typeof ApplicationResponseSchemaEnvTypeEnum];


/**
 * Check if a given object implements the ApplicationResponseSchema interface.
 */
export function instanceOfApplicationResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "env_type" in value;

    return isInstance;
}

export function ApplicationResponseSchemaFromJSON(json: any): ApplicationResponseSchema {
    return ApplicationResponseSchemaFromJSONTyped(json, false);
}

export function ApplicationResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'quentry_careteam_id': !exists(json, 'quentry_careteam_id') ? undefined : json['quentry_careteam_id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'env_type': json['env_type'],
        'organization_id': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'client_id': !exists(json, 'client_id') ? undefined : json['client_id'],
        'last_used_at': !exists(json, 'last_used_at') ? undefined : (json['last_used_at'] === null ? null : new Date(json['last_used_at'])),
    };
}

export function ApplicationResponseSchemaToJSON(value?: ApplicationResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quentry_careteam_id': value.quentry_careteam_id,
        'name': value.name,
        'description': value.description,
        'env_type': value.env_type,
    };
}

