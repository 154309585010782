import React, {useState} from 'react';
import {Navigate, NavLink, useLoaderData} from 'react-router-dom';
import {Col, FormCheck, Row, Stack} from 'react-bootstrap';
import OrganizationLayout, {OrganizationLoader} from './OrganizationLayout';
import {ApplicationsApi, Configuration, CredentialsApi} from '../sdk';
import {useOrganizationId} from '../Helper';

export async function ApplicationLoader(apiClientConfiguration: Configuration, orgId: string, appId: string) {
	const applicationPromise = (new ApplicationsApi(apiClientConfiguration)).getApplication({organization_id: +orgId, application_id: +appId});
	const credentialPromise = (new CredentialsApi(apiClientConfiguration)).getApplicationCredential({organization_id: +orgId, application_id: +appId});
	return {...await OrganizationLoader(apiClientConfiguration), application: await applicationPromise, credential: await credentialPromise};
}

export default function Application() : JSX.Element {
	const orgId = useOrganizationId();
	const {application, credential} = useLoaderData() as Awaited<ReturnType<typeof ApplicationLoader>>;
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	function displayDate(date: Date | undefined | null) {
		return date ? new Date(date).toLocaleString() : '-';
	}

	if(orgId && application.organization_id !== orgId) {
		// redirect to default org page in case the application is not for the selected organization
		return <Navigate to={`/organization/${orgId}/applications`}/>;
	}

	return OrganizationLayout({
		children:
			<div>
				<div className="pages_title">{application.name}</div>
				<div className="card_container mb-4">
					<div className="card_title">App Details</div>
					<div className="mt-4">
						<Stack direction="vertical" gap={5}>
							<Row>
								<Col>
									<Stack className="mt-4" direction="vertical" gap={2}>
										<span className="input_title mb-3">Basic</span>
										<span className="view_property">ID: {application.id}</span>
										<span className="view_property">Name: {application.name}</span>

										<span className="view_property">Description: {application.description}</span>
										<span className="view_property">Env-Type: {application.env_type}</span>
									</Stack>
									<Stack className="mt-4" direction="vertical" gap={2}>
										<span className="input_title mb-3">Tenant IDs</span>
										<span className="view_property">Quentry CareTeam ID: {application.quentry_careteam_id}</span>
									</Stack>
								</Col>
								<Col>
									<Stack className="mt-4" direction="vertical" gap={2}>
										<span className="input_title mb-3">Activity</span>
										<span
											className="view_property">Created: {displayDate(application.created_at)}</span>
										<span
											className="view_property">Updated: {displayDate(application.updated_at)}</span>
										<span
											className="view_property">Deleted at: {displayDate(application.deleted_at)}</span>
										<span
											className="view_property">Last used at: {displayDate(application.last_used_at)}</span>
									</Stack>
									<Stack className="mt-4" direction="vertical" gap={2}>
										<span className="input_title mb-3">Credentials</span>
										<span className="view_property">Client ID: {application.client_id}</span>
										<span className="view_property">Client Secret: {isPasswordVisible ? credential.client_secret : '******************************'}</span>
										<FormCheck type="switch" label="Show Client Secret" checked={isPasswordVisible} onChange={() => setIsPasswordVisible(!isPasswordVisible)}></FormCheck>
									</Stack>
								</Col>
							</Row>
							<Stack direction="horizontal" gap={3}>
								<NavLink className="content_light_button ms-auto"
										 to={`/organization/${orgId}/applications`}>Cancel</NavLink>
								<NavLink className="content_dark_button" to={`update`}>Edit</NavLink>
							</Stack>
						</Stack>
					</div>
				</div>
			</div>
	});
}
