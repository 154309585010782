/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CredentialRequestSchema,
  CredentialResponseSchema,
  GetAdmin400Response,
  GetCurrentDeveloper4XXResponse,
  ValidateRequestSchema,
} from '../models';
import {
    CredentialRequestSchemaFromJSON,
    CredentialRequestSchemaToJSON,
    CredentialResponseSchemaFromJSON,
    CredentialResponseSchemaToJSON,
    GetAdmin400ResponseFromJSON,
    GetAdmin400ResponseToJSON,
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    ValidateRequestSchemaFromJSON,
    ValidateRequestSchemaToJSON,
} from '../models';

export interface GetApplicationCredentialRequest {
    organization_id: number;
    application_id: number;
}

export interface PostCredentialsValidateRequest {
    ValidateRequestSchema: ValidateRequestSchema;
}

export interface PutApplicationCredentialRequest {
    organization_id: number;
    application_id: number;
    CredentialRequestSchema: CredentialRequestSchema;
}

/**
 * 
 */
export class CredentialsApi extends runtime.BaseAPI {

    /**
     * Retrieves a credential
     */
    async getApplicationCredentialRaw(requestParameters: GetApplicationCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CredentialResponseSchema>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling getApplicationCredential.');
        }

        if (requestParameters.application_id === null || requestParameters.application_id === undefined) {
            throw new runtime.RequiredError('application_id','Required parameter requestParameters.application_id was null or undefined when calling getApplicationCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/applications/{application_id}/credential`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))).replace(`{${"application_id"}}`, encodeURIComponent(String(requestParameters.application_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredentialResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves a credential
     */
    async getApplicationCredential(requestParameters: GetApplicationCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CredentialResponseSchema> {
        const response = await this.getApplicationCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validates session manager credentials
     */
    async postCredentialsValidateRaw(requestParameters: PostCredentialsValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ValidateRequestSchema === null || requestParameters.ValidateRequestSchema === undefined) {
            throw new runtime.RequiredError('ValidateRequestSchema','Required parameter requestParameters.ValidateRequestSchema was null or undefined when calling postCredentialsValidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/credentials/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateRequestSchemaToJSON(requestParameters.ValidateRequestSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validates session manager credentials
     */
    async postCredentialsValidate(requestParameters: PostCredentialsValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postCredentialsValidateRaw(requestParameters, initOverrides);
    }

    /**
     * Updates a credential
     */
    async putApplicationCredentialRaw(requestParameters: PutApplicationCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling putApplicationCredential.');
        }

        if (requestParameters.application_id === null || requestParameters.application_id === undefined) {
            throw new runtime.RequiredError('application_id','Required parameter requestParameters.application_id was null or undefined when calling putApplicationCredential.');
        }

        if (requestParameters.CredentialRequestSchema === null || requestParameters.CredentialRequestSchema === undefined) {
            throw new runtime.RequiredError('CredentialRequestSchema','Required parameter requestParameters.CredentialRequestSchema was null or undefined when calling putApplicationCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/applications/{application_id}/credential`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))).replace(`{${"application_id"}}`, encodeURIComponent(String(requestParameters.application_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialRequestSchemaToJSON(requestParameters.CredentialRequestSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a credential
     */
    async putApplicationCredential(requestParameters: PutApplicationCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putApplicationCredentialRaw(requestParameters, initOverrides);
    }

}
