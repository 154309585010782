/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CurrentApplicationSchema,
  GetCurrentDeveloper4XXResponse,
} from '../models';
import {
    CurrentApplicationSchemaFromJSON,
    CurrentApplicationSchemaToJSON,
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
} from '../models';

/**
 * 
 */
export class ApplicationApi extends runtime.BaseAPI {

    /**
     * Retrieves current application entry
     */
    async getCurrentApplicationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CurrentApplicationSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AppAuth", []);
        }

        const response = await this.request({
            path: `/application`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentApplicationSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves current application entry
     */
    async getCurrentApplication(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CurrentApplicationSchema> {
        const response = await this.getCurrentApplicationRaw(initOverrides);
        return await response.value();
    }

}
